/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import Layout from "../components/layout"
import PostCard from "../components/post-card"
import Seo from "../components/seo"

export const pageQuery = graphql`
  query GPMQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 585, height: 439)
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { frontmatter: { template: { eq: "murals" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            thumbnailImage {
              publicURL
            }
          }
        }
      }
    }
  }
`
const MuralGallery = ({ data }) => {
  const { markdownRemark, posts } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  console.log(posts)
  return (
    <Layout className="page">
      <Seo title={frontmatter.title} description={excerpt} />
      <div className="container">
        <div style={{ justify: "center" }}>
          <div className="masonry-container">
            {posts.edges.map(elem => (
              <div className="item">
                <div className="art-card bg">
                  <a href={elem.node.frontmatter.slug}>
                    <h3 className="title">{elem.node.frontmatter.title}</h3>
                    {/* <div className="post-content"> */}
                    <img
                      src={elem.node.frontmatter.thumbnailImage?.publicURL}
                    ></img>
                    {/* </div> */}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MuralGallery
